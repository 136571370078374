@import url('https://fonts.googleapis.com/css2?family=Public+Sans&display=swap');


@font-face {
  font-family: 'Uber Move';
  src: url('../fonts/UberMove-Medium.eot');
  src: url('../fonts/UberMove-Medium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/UberMove-Medium.woff2') format('woff2'),
    url('../fonts/UberMove-Medium.woff') format('woff'),
    url('../fonts/UberMove-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Uber Move';
  src: url('../fonts/UberMove-Bold.eot');
  src: url('../fonts/UberMove-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/UberMove-Bold.woff2') format('woff2'),
    url('../fonts/UberMove-Bold.woff') format('woff'),
    url('../fonts/UberMove-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}




* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
}

html,
body,
address,
blockquote,
div,
dl,
form,
h1,
h2,
h3,
h4,
h5,
h6,
ol,
p,
pre,
table,
ul,
dd,
dt,
li,
tbody,
td,
tfoot,
th,
thead,
tr,
button,
del,
ins,
map,
object,
a,
abbr,
acronym,
b,
bdo,
big,
br,
cite,
code,
dfn,
em,
i,
img,
kbd,
q,
samp,
small,
span,
strong,
sub,
sup,
tt,
var,
legend,
fieldset,
p,
aside,
header,
footer,
section,
article {
  border: medium none;
  margin: 0;
  padding: 0;
}

:focus {
  outline: none !important;
}

img,
fieldset {
  border: 0 none;
}

a {
  border: none;
  outline: none;
}

input[type="submit"],
select {
  -webkit-appearance: none;
}

img {
  border: none;
  height: auto;
  max-width: 100%;
  outline: none;
  vertical-align: middle;
}

iframe {
  vertical-align: middle;
}

.subscribe-main .card-heading h2 {
  font-size: 20px;
}

a:hover {
  text-decoration: none;
  color: red;
}

.clear {
  clear: both;
}

ul::after {
  clear: both;
  content: "";
  display: block;
}

ul {
  list-style: none;
}

body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 27px;
  color: #474646;
  -webkit-font-smoothing: antialiased;
  background: #000;
  font-family: "Poppins", sans-serif;
}

.header a.active.nav-link:after {
  position: absolute;
  width: 60px;
  height: 2px;
  background: #4abaea;
  content: "";
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
}

.signup-bot label {
  color: #fff;
}

.submit-btn {
  background: #4abaea !important;
  color: #fff !important;
  display: block;
  margin: 30px auto 0;
  width: 160px;
  border-radius: 30px;
  height: 45px;
}

input[data-autocompleted] {
  background-color: transparent !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #ffffff;
}

.signup-bot input,
input:-webkit-autofill,
input:-webkit-autofill:focus {
  background: none !important;
  outline: none !important;
  box-shadow: none !important;
  height: 45px;
  border: 1px solid #3c3c3c !important;
  color: #fff !important;
}

.navbar-light .navbar-toggler-icon {
  filter: invert(1);
}

.header .navbar-light .navbar-toggler {
  color: #fff !important;
  border-color: #fff !important;
}

.header a.active.nav-link {
  color: #4abaea !important;
  position: relative;
}

.header a.nav-link {
  color: #fff !important;
  margin: 0 20px;
  position: relative;
}

.tab-heading-area {
  text-align: center;
  color: #ffff;
}

.about-us-bio {
  /* background: white; */
  padding: 0;
  margin-top: 50px;
  margin-bottom: 50px;
  color: white;
  border-radius: 10px;
}

.about-us-bio ul {
  list-style-type: disc;
  margin: 0 19px;
}

.about-us-bio ul li {
  padding-bottom: 10px;
}

.thanks-message {
  color: #4abaea;
}

.signup-bot {
  max-width: 700px;
  margin: 55px auto 100px;
  background: #171717;
  padding: 30px;
  border-radius: 15px;
}

.tab-content-plans .nav-tabs {
  border-bottom: none;
  background: #171717;
  max-width: 335px;
  margin: 40px auto;
  padding: 12px;
  justify-content: center;
  border-radius: 50px;
}

.tab-content-plans li.nav-item {
  width: 50%;
}

.tab-content-plans .nav-tabs .nav-link {
  margin-bottom: -1px;
  background: 0 0;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  width: 100%;
  color: #fff;
  border-radius: 50px;
}

.tab-content-plans .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background: #4abaea;
  border-radius: 50px;
  color: #ffff;
}

.plans-content-area:hover {
  background: #4abaea;
  color: #000;
  transform: scale(1.1);
  transition: 0.3s all;
}

.plans-content-area {
  background: #171717;
  padding: 25px;
  border-radius: 30px;
  min-height: 455px;
  color: #fff;
  transition: 0.3s all;
  cursor: pointer;
}

.disable-plan {
  cursor: unset;
  background: gray;
}

body .disable-plan button {
  background: white;
  color: black;
}

.disable-plan:hover {
  transition: unset;
  transform: scale(1);
  background: gray;
}

.change-passwd {
  width: 190px !important;
}



body .disable-plan h2, body .disable-plan h6 {
  color: black;
}

.disable-plan li, .disable-plan hr {
  color: black;
}


.disable-plan li img {
  filter: brightness(0);
}


.loader {
  width: 100%;
  height: 100vh;
  position: fixed;
  background: #000000bf;
  top: 0;
  z-index: 9999;
}

.loader div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.navbar-brand {
  cursor: pointer;
}

.zoom-in-zoom-out {
  animation: zoom-in-zoom-out 3s ease-out infinite;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.1, 1.1);
  }

  100% {
    transform: scale(1, 1);
  }
}

/* .emoji {
  cursor: url('../images/cursor.png') 20 20, auto;
} */

.plans-content-area h2 {
  color: #4abaea;
  font-weight: 600;
  margin-top: 5px;
}

.plans-content-area ul li, .subscription-plan-area ul li {
  padding-bottom: 10px;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.agree-condition {
  display: flex;
  align-items: center;
}

.agree-condition input {
  margin-right: 6px;
}

div#swal2-html-container {
  font-size: 15px;
}

.free-trial-ava {
  position: relative;
}

.agree-terms-condition .agree-condition input {
  margin-right: 6px;
  margin-top: 6px;
}

.agree-terms-condition .agree-condition {
  align-items: flex-start;
}

.agree-paypal .modal-dialog.modal-dialog-centered .modal-content {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.agree-paypal .modal-dialog.modal-dialog-centered {
  max-width: 600px;
}

button.cross-item.btn.btn-primary i {
  color: #8d8787;
  font-size: 35px;

}

button.cross-item.btn.btn-primary {
  background: transparent;
  border: none;
  padding: 0;
  position: absolute;
  right: 10px;
  top: 7px;
}

h2#swal2-title {
  font-weight: normal;
  font-size: 16px !important;
  line-height: normal;
}

.view-reason .modal-dialog.modal-dialog-centered .modal-content {
  height: 200px;
}

.view-reason .modal-dialog.modal-dialog-centered {
  max-width: 600px;
}

button.cross-item.btn.btn-primary:focus {
  box-shadow: none;
}

.plans-content-area h6 {
  /* font-size: 15px; */
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 220px;
  overflow: hidden;
}

.plans-content-area button {
  background: linear-gradient(180deg, #4abaea 0%, #15a6e5 100%);
  width: 100%;
  padding: 12px;
  color: #ffff;
  border-radius: 40px;
  margin-top: 15px;
}

.plans-content-area h2 span,
.subscription-plan-area h2 span {
  font-size: 18px;
  font-weight: 500;
}

.tab-content-plans .tab-content {
  margin-top: 70px;
}

.plans-content-area ul,
.subscription-plan-area ul {
  height: 225px;
  overflow: auto;
}

.tab-area {
  padding: 50px 0 130px 0;
}

.plans-content-area:hover button {
  background: #ffffff;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.25);
  color: #000;
}

.plans-content-area:hover h2 {
  color: #000;
}

.footer {
  background-image: url("../images/ellipse.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 120px 0 20px 0;
  color: #fff;
  background-position: center top;
}

.prom-box button.btn.btn-try {
  margin: auto;
  display: block;
}

.social-links h6,
.footer-email-area h6 {
  text-transform: uppercase;
  font-size: 18px;
  margin-bottom: 30px;
}

.social-links ul li i {
  font-size: 22px;
  cursor: pointer;
  padding: 10px 30px 10px 0;
}

.social-links ul {
  display: flex;
}

.footer-email-area input.form-control {
  background: #000;
  border: none;
  border-radius: 50px;
  padding: 6px 6px 6px 18px;
  box-shadow: none !important;
  color: #fff !important;
}

.footer-email-area .input-group {
  height: 55px;
  background: #000;
  border-radius: 50px;
}

.footer-email-area .input-group-text {
  background: #4abaea;
  border: none;
  color: #ffff;
  padding: 0 45px;
  border-radius: 50px !important;
  z-index: 1;
  cursor: pointer;
}

.footer-bottom-right {
  justify-content: flex-end;
  display: flex;
}

.footer-bottom-right ul {
  display: flex;
}

.footer-bottom-right ul li:after {
  content: "";
  position: absolute;
  background: #527aa2;
  width: 2px;
  height: 100%;
  right: -32px;
}

.footer-bottom-right ul li {
  margin-left: 60px;
  position: relative;
  cursor: pointer;
}

.footer-bottom-right ul li:last-child:after {
  display: none;
}

.subscription-area {
  padding: 50px 0 90px 0;
}

.subscription-plan-area {
  background: #4abaea;
  color: #000;
  padding: 25px;
  border-radius: 30px;
}

.subscription-plan-area h2 {
  font-weight: 600;
}

.subscription-plan-area button {
  background: #ffffff;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.25);
  width: 100%;
  padding: 12px;
  border-radius: 40px;
  margin-top: 15px;
  font-weight: 500;
  color: #000;
}

.subscription-area-right {
  background: #171717;
  padding: 25px;
  border-radius: 30px;
  height: 100%;
}

.subscription-area-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.subscription-prize h5 {
  font-size: 18px;
  font-weight: 600;
  color: #4abaea;
}

.white-box {
  background: white;
  padding: 20px 20px;
  border-radius: 10px;
}

.subscription-prize h5 i {
  color: #fff;
  margin-right: 5px;
}

.subsciption-area-center button {
  background: #4abaea;
  box-shadow: 0px 3px 5px rgb(0 0 0 / 25%);
  padding: 12px 65px;
  border-radius: 30px;
  margin-top: 0px;
  color: #000;
  font-weight: 500;
}

.main-all {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 62%;
}

.header span.navbar-brand img {
  width: 90px;
}

.subsciption-area-center {
  text-align: center;
  color: #fff;
  margin-top: 0px;
}

p.signup-back-btn a {
  color: #4abaea;
  /* font-weight: 600; */
}

p.forgot-password-link {
  text-align: end;
  color: #4abaea;
  text-decoration: underline;
  font-size: 15px;
}

p.forgot-password-link a {
  color: #4abaea;
}

.disable-paypal {
  color: grey;
}

.subscription-plan-area ul li img {
  filter: brightness(0);
  margin-right: 5px;
}

.plans-content-area img {
  margin-right: 5px;
}

.plans-content-area:hover img {
  filter: brightness(0);
}

.header .navbar-toggler:focus {
  box-shadow: none;
}

.error-msg {
  color: red;
  text-align: left;
}

.swal2-styled.swal2-confirm {
  color: #fff;
  background: #4abaea !important;
  border: #4abaea !important;
}

.emoji {
  background-image: url("../images/background-image.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
}

.form .field-group input {
  background: #000;
  border: none !important;
  border-radius: 50px;
  padding: 14px 70px 14px 17px;
  box-shadow: none !important;
  color: #fff !important;
  width: 100%;
  height: 45px;
}

.back-to-login a {
  color: #fff !important;
  text-decoration: none;
}

.swal2-title {
  font-size: 22px !important;
  color: #000 !important;
}

.swal2-popup {
  width: 24em !important;
}

.field-group span input[type="submit"] {
  background: #4abaea;
  border: none;
  color: #ffff;
  padding: 8px 35px;
  border-radius: 50px !important;
  z-index: 1;
  cursor: pointer;
  position: relative;
  right: 111px;
  height: 45px;
}

p.signup-back-btn {
  text-align: center;
  margin-top: 20px;
  color: #fff;
}

p.signup-back-btn img {
  margin-right: 4px;
}

form .field-group {
  display: flex;
  height: 45px;
}

.form .field-group input::placeholder {
  font-size: 14px;
}

.disclaimer.plan-detail {
  border: 1px solid;
  padding: 20px;
  border-radius: 10px;
  width: 750px;
  margin: 30px auto;
}

.plans-content-area ul::-webkit-scrollbar {
  width: 2px;
  background: white;
}

.plans-content-area ul::-webkit-scrollbar-thumb {
  width: 3px;
  background: rgb(253, 253, 253);
  height: 1px;
}

.plans-content-area ul::-webkit-scrollbar-track {
  width: 3px;
  background: rgb(99, 99, 99);
  height: 3px;
  max-height: 180px;
}

.subscription-plan-area ul::-webkit-scrollbar {
  width: 2px;
  background: white;
}

.subscription-plan-area ul::-webkit-scrollbar-thumb {
  width: 3px;
  background: rgb(253, 253, 253);
  height: 1px;
}

.subscription-plan-area ul::-webkit-scrollbar-track {
  width: 3px;
  background: rgb(99, 99, 99);
  height: 3px;
  max-height: 180px;
}

.login form input {
  background: #000000;
  border: 1px solid #FFFFFF;
  border-radius: 10px;
  height: 61px;
  font-style: normal;
  font-weight: 500;
  padding: 0 20px;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
}

p.logo-sub-title {
  font-family: 'Public Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  padding-bottom: 15px;
  line-height: 28px;
  color: #FFFFFF;
  position: relative;
}

button.btn.back-login {
  color: white;
  text-align: center;
  width: 100%;
}

button.btn.back-login i.fa.fa-chevron-left {
  margin-right: 10px;
}

.login form input:focus, input:focus {
  background: transparent;
  color: white;
}

button.btn.btn-sign {
  background: #4abaea !important;
  color: #fff !important;
  display: block;
  margin: 30px auto 0;
  width: 160px;
  border-radius: 30px;
  font-size: 1rem;
  height: 45px;
}

.note-disabled {
  font-size: 12px;
  font-style: italic;
}

.social-links li img {
  margin-top: 6px;
}

.modal-view .modal-dialog .modal-content .modal-body {
  overflow-y: scroll;
  max-height: 500px;
}


.modal-view .modal-dialog .modal-content .modal-body::-webkit-scrollbar {
  width: 5px;
}

.modal-view .modal-dialog .modal-content .modal-body::-webkit-scrollbar-thumb {
  width: 5px;
  background-color: #4abaea;
}

.subscript-main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80%;
}

.subscript-main .subsciption-area-center {
  margin-top: 0px;
}

.modal-view .modal-dialog .modal-content .modal-body::-webkit-scrollbar-track {
  width: 5px;
  background-color: white;
}

.modal-view .modal-title {
  text-align: center;
  width: 100%;
}

.modal-view .modal-header {
  border: none;
  box-shadow: 1px 0px 10px rgb(208 208 208 / 50%);
}

.social-links li a, .social-links li a:hover {
  color: white;
}

.modal-view .modal-header .btn-close {
  border: 2px solid #000;
  border-radius: 50%;
  color: 000;
  font-size: 14px;
}


/********Landing page*******/
.banner {
  /* background: #4b9dc1; */
  background-image: url("../../assets/images/bg-landing.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 893px;
  background-position: center center;
  background-color: white;
  position: relative;
}

img.banner-right-figure {
  position: absolute;
  right: 0;
  top: 0px;
}

.banner-box {
  margin: 100px 0px;
}

.banner-caption h1 {
  font-style: normal;
  font-family: 'Uber Move';
  font-style: normal;
  font-weight: 700;
  font-size: 80px;
  line-height: 77px;
  text-transform: uppercase;
  background: linear-gradient(180deg, #FFFFFF 0%, #36506D 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
}

.banner-caption h5 {
  font-size: 20px;
  color: #fff;
  padding-bottom: 20px;
}

.banner-caption h1 span {
  display: block;
  text-transform: capitalize;
  -webkit-text-fill-color: white;
}

.banner-caption {
  color: white;
}

.banner-caption p {
  font-family: 'Uber Move Text';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 34px;
  color: #FFFFFF;
  padding: 10px 0px 0px;
}


button.btn.btn-try {
  background: #ffffff;
  padding: 15px 40px;
  font-size: 20px;
  color: black;
  margin-top: 20px;
  border-radius: 50px;
}

img.bot-logo-banner.gift.mover {
  right: 0;
  text-align: center;
  margin: auto;
  bottom: -100px;
}

button.btn.btn-try:hover {
  background: #4abaea;
  color: white;
}

.happy-client-all {
  background: white;
  text-align: center;
  padding: 50px 40px;
}


.happy-client {
  padding: 50px 40px;
  text-align: center;
  background: #FFFFFF;
  box-shadow: 0px 0px 25px rgba(9, 28, 112, 0.11);
  border-radius: 30px;
  margin: 0 24px;
}

.happy-client-all h2 {
  padding-bottom: 30px;
  font-style: normal;
  font-weight: 700;
  font-family: 'Uber Move';
  font-size: 47px;
  line-height: 85px;
  color: #182037;
}

.happy-client-all .card {
  border: none;
}

.happy-client h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  font-family: 'Uber Move';
  line-height: 40px;
  color: #182037;
  text-align: left;
}

.client-box {
  display: flex;
  align-items: center;
}

.client-box .figure {
  margin-right: 20px;
}

.happy-client i {
  background: #4abaea;
  padding: 25px;
  border-radius: 50%;
  color: white;
}

.happy-client p {
  font-style: normal;
  font-weight: 500;
  font-family: 'Uber Move';
  font-size: 18px;
  line-height: 31px;
  color: rgba(102, 102, 102, 0.87);
}

.about-us {
  padding: 50px 0px;
  background: white;
}



.figure-left img.abt-us {
  max-width: 70%;
  /* border: 1px solid #eee; */
  border-radius: 10px;
  padding: 30px;
}

.about-info {
  background: black;
  border-top-left-radius: 200px;
  border-bottom-left-radius: 200px;
  padding: 100px 100px;
}

.figure-left {
  margin-left: 151px;
}

.about-us .container {
  max-width: 100%;
}

.about-info h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  font-family: 'Uber Move';
  line-height: 100%;
  padding-left: 75px;
  text-transform: uppercase;
  position: relative;
  color: #FFFFFF;
}

.btn.btn-learn img {
  margin-left: 15px;
}

.about-info h2:after {
  border: 3px solid #49BAEA;
  content: "";
  position: absolute;
  width: 50px;
  left: 0;
  top: 18px;
}

.about-info p {
  padding: 15px 0px;
  font-family: 'Uber Move';
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 150%;
  color: #FFFFFF;
}

button.btn.btn-learn {
  background: #4abaea;
  padding: 15px 40px;
  font-family: 'Uber Move';
  font-size: 18px;
  color: white;
  margin-top: 15px;
  border-radius: 50px;
}

.welcome {
  background: white;
  padding: 50px 40px;
}

.welcome .box {
  background: #000;
  padding: 60px 40px;
  color: white;
  text-align: center;
  position: relative;
  background-repeat: no-repeat;
  background-position: bottom right;
  border-radius: 20px;
  background-image: url("../../assets/images/triangle.png");
}

.box h3 {
  padding-bottom: 10px;
  font-style: normal;
  font-family: 'Uber Move';
  font-weight: 700;
  font-size: 45px;
  line-height: 63px;
  text-align: center;
  color: #FFFFFF;
}

.how-it h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 45px;
  line-height: 63px;
  font-family: 'Uber Move';
  text-align: center;
  color: #182037;
}

.how-it .heading-top p {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 33px;
  color: rgba(0, 0, 0, 0.87);
  padding: 20px 0px 40px;
}

.box p {
  font-family: 'Uber Move';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 33px;
  color: rgba(255, 255, 255, 0.87);
}

.transaction-history-main table th, .transaction-history-main table th, .transaction-history-main table tr td {
  color: white;
}

.how-it {
  padding: 50px 40px 61px;
  background-color: white !important;
  text-align: center;
  background: url("../../assets/images/bubble-box.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.icon-wallet {
  position: relative;
  margin-top: 50px;
}

.icon-wallet p {
  font-weight: bold;
  padding-bottom: 14px;
  display: block;
  padding-top: 15px;
}

.icon-wallet span {
  background: #00A7EF;
  position: absolute;
  top: 0px;
  width: 40px;
  height: 40px;
  display: flex;
  z-index: 0;
  right: 58px;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 50%;
}

img.line-arrow {
  position: absolute;
  top: 0;
  right: -50px;
  top: 50px;
}

.how-it .col-lg-3:nth-of-type(2) img.line-arrow {
  transform: rotateX(180deg);
}

.how-it .col-lg-3 {
  position: relative;
}

.how-it h2 span {
  color: #4abaea;
}



.icon-wallet p {
  font-style: normal;
  font-weight: 700;
  padding-top: 0px;
  font-size: 21px;
  line-height: 63px;
  font-family: 'Uber Move';
  text-align: center;
  color: #333333;
}

.reddit {
  padding: 150px 40px 0px;
  background: #fff;
}

.crypto {
  text-align: center;
}

.trial-period {
  color: #00A7EF;
  margin-left: 0;
  opacity: 0.5;
  display: block;
  font-size: 16px;
  padding-top: 0;
  position: absolute;
  top: 77px;
}

.welcome .box:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: url("../../assets/images/triangle-left.png");
  background-repeat: no-repeat;
  z-index: 9999;
  width: 300px;
  height: 375px;
  opacity: 0.20;
  transform: rotateX(180deg);
}

.trading {
  background: url("../../assets/images/trading-bg.png") no-repeat;
  background-size: 100% 100%;
  background-position: center center;
  background-color: black;
  height: 1030px;
}

.trading-caption h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  font-family: 'Uber Move';
  line-height: 53px;
  text-align: center;
  color: #FFFFFF;
}

.trading-caption p {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  font-family: 'Uber Move';
  line-height: 33px;
  padding: 20px 2px;
  text-align: center;
  color: rgba(255, 255, 255, 0.87);
}

.trading-caption {
  width: 710px;
  margin: auto;
  text-align: center;
}

.telegram {
  padding: 0px 40px;
  background: white;
  position: relative;
}

img.triangle {
  position: absolute;
  left: 0;
  bottom: 0;
}

img.triangle-right {
  position: absolute;
  right: 0;
  bottom: 0;
  transform: rotateY(180deg);
}

.bot-figure {
  text-align: center;
}

.bot-text h2 {
  padding: 25px 0px;
}

.promoter-coins {
  padding: 0px 40px 200px;
  background: white;
  position: relative;
  background-size: cover;
  background-position: center center;
}

span.view-reason {
  color: #4cbbe8;
  cursor: pointer;
  text-decoration: underline;
}

.modal-footer button.btn.btn-primary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

img.bannercoins {
  position: absolute;
  right: 25px;
  bottom: 61px;
  z-index: -1;
}

.foot-check .agree-condition {
  align-items: flex-start;
}

.foot-check .agree-condition input {
  margin-top: 7px;
}

button.btn.btn-danger.btn.btn-primary {
  background: #dc3545;
  border: #dc3545;
}

.prom-box {

  background: #000000;
  padding: 60px 60px;
  color: white;
  z-index: 1;
  text-align: left;
  position: relative;
  border-radius: 60px;
}

.prom-box h4 {
  padding-bottom: 10px;
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 100%;
  color: #FFFFFF;
  font-family: 'Uber Move';
  line-height: 70px;
}

table.table.table-responsive.text-black.bg-white.radius-20 {
  border-radius: 30px;
  margin: 50px 0px;
  table-layout: fixed;
}

.table.radius-20 tr td {
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 34px;
  color: #333333;
}

table.table.table-responsive.text-black.bg-white.radius-20 tr:last-child, table.table.table-responsive.text-black.bg-white.radius-20 tr:last-child td {
  border-bottom: none;
}

table.table.table-responsive.text-black.bg-white.radius-20 tr td:first-child {
  padding-left: 40px;
}

table.table.table-responsive.text-black.bg-white.radius-20 tr td:last-child {
  text-align: center;
}

table.table.table-responsive.text-black.bg-white.radius-20 tr td:last-child a {
  border: 1px solid #000000;
  border-radius: 3px;
  padding: 7px 15px;
}

table.table.table-responsive.text-black.bg-white.radius-20 tr td:last-child a i.fa.fa-arrow-up {
  padding: 0 8px;
}

table.table.table-responsive.text-black.bg-white.radius-20 tr, table.table.table-responsive.text-black.bg-white.radius-20 tr td {
  padding: 10px 4px;
  border-bottom: 1px solid #E5E5E5;
}

button.btn.btn-try i {
  font-size: 15px;
  margin-left: 5px;
}

img.bitcoin-pic.mover {
  position: absolute;
  right: 0;
  bottom: 0;
  -webkit-animation: mover 2s infinite alternate;
  animation: mover 2s infinite alternate;
}

img.gift.mover {
  position: absolute;
  bottom: 0;
  left: 0;
  -webkit-animation: mover 2s infinite alternate;
  animation: mover 2s infinite alternate;
}

@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-20px);
  }
}

@keyframes mover {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-20px);
  }
}


.prom-box p {
  font-size: 18px;
}

.list-search {
  margin-top: 30px;
  display: flex;
  max-width: 750px;
  align-items: center;
  position: relative;
  margin: 30px auto;
}

.list-search input.form-control {
  height: 60px;
}

.list-search button.btn.btn-list {
  width: 200px;
  background: #4abaea;
  height: 54px;
  border-radius: 0;
  position: absolute;
  right: 3px;
  color: white;
  font-size: 22px;
}

.list-search input:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

.user-info .card-heading h6 {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 11px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #FFFFFF;
}

.user-info .card-heading {
  display: flex;
  justify-content: space-between;
}

.user-info .card-heading a {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  line-height: 100%;
  text-decoration-line: none;
  color: #FFFFFF;
  border: 1px solid #fff;
  padding: 12px 25px;
  border-radius: 50px;
}

.user-info .card-heading a i {
  padding-right: 10px;
}

.userinfo input {
  background: #282828;
  border-radius: 100px;
  height: 50px;
  border: none;
  width: 100%;
  color: white;
}



.user-detail, .user-info {
  background: #171717;
  padding: 40px;
  border-radius: 0px;
  color: #fff;
  transition: 0.3s all;
}

.disclaimer p, .disclaimer h4 {
  font-style: italic;
  color: white;
}


.subsciption-area-center button.btn.btn-unset[disabled] {
  margin-bottom: 10px;
}

.subsciption-area-center button.btn.btn-unset[disabled]+h5 {
  padding-bottom: 15px;
}

.user-info {
  border-right: 1px solid #292929;
  height: 100%;
}

.user-detail {
  padding-left: 20px;
  position: relative;
}

.user-detail.mt-0.transaction-history-main {
  border-top: 1px solid #2b2b2b;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.bg-plan-subscribe {
  background: #171717;
  padding: 0;
  border-radius: 15px;
}

.user-detail .card-heading h3 {
  font-style: normal;
  font-size: 16px;
  line-height: 11px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #FFFFFF;
}

.user-detail .card-heading {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px;
}

.user-detail .card-heading h6 {
  font-size: 20px;
}

.user-info h2 span {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: #4ABAEA;
}

.user-plan span {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: #4ABAEA;
}

.user-plan ul li img {
  margin-right: 7px;
  margin-top: 6px;
}

.user-plan ul li {
  padding-bottom: 5px;
  padding-top: 5px;
  display: flex;
  align-items: flex-start;
}
.user-plan ul {
  margin-top: 10px;
  column-count: 2;
}

.user-plan button {
  background: #4ABAEA;
  border-radius: 50px;
  height: 45px;
  width: 165px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 11px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #FFFFFF;
  justify-content: center;
}

.user-detail button.btn.btn-software.btn.btn-unset {
  justify-content: center;
  margin: 35px 0px;
  width: 190px;
}

.user-detail button:hover {
  color: white;
}

.disclaimer {
  margin-top: 20px;
}

.mb-5.trial-plan.col-md-3 {
  position: relative;
}

img.free-trial {
  position: absolute;
  width: 24%;
  z-index: 1;
  right: 0px;
}

.user-detail button.btn.btn-software.btn.btn-unset i {
  font-size: 20px;
  margin-right: 8px;
}

.disclaimer h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #FF0000;
}

.userinfo input:focus {
  background: #000000;
  outline: none;
  color: white;
  box-shadow: none;
}

.profile-pic {
  position: relative;
  width: 151px;
  height: 151px;
  border-radius: 50%;
  margin: auto;
  border: 1px solid #fff;
  margin-bottom: 35px;
}

.profile-pic img {
  width: 100%;
  border-radius: 50%;
  object-fit: cover;
  height: 151px;
}

input.choose-file {
  background: none;
  position: absolute;
  padding: 70px 0px;
  left: 0;
  opacity: 0;
  width: 170px;
  z-index: 1;
  cursor: pointer;
}

input.choose-file[disabled] {
  cursor: default;
}

.shimmer-img {
  border-radius: 50%;
}


.profile-pic i.fa.fa-pencil {
  position: absolute;
  right: -8px;
  color: black;
  background: white;
  padding: 5px 5px;
  border-radius: 5px;
  z-index: 0;
  top: 50px;
}

.user-detail hr, .user-info hr {
  margin: 30px 0px;
}

button.ms-auto.update.btn.btn-unset {
  background: #282828;
  float: none;
  margin: 5px 0px;
  padding: 8px 25px;
  border: 1px solid;
  border-radius: 50px;
  color: white;
}

button.ms-auto.update.btn.btn-unset:focus {
  box-shadow: none;
}

.userinfo input:disabled {
  color: #fff;
  border: none;
  background: #282828;
}


button.change-pass.btn.btn-unset {
  border: 1px solid;
  margin: 0 0 0 16px;
  background: transparent;
  border-radius: 50px;
  height: 45px;
  width: 165px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 11px;
  align-items: center;
  letter-spacing: -0.02em;
  color: #FFFFFF;
  justify-content: center;
}

ul.pagination li.active a {
  color: white;
}


ul.pagination li:first-child, ul.pagination li:last-child {
  display: none;
}

.table-main .pagination li a, .userlist .pagination li a {
  color: #000;
}


.table-main .pagination, .userlist .pagination {
  justify-content: center;
  margin-top: 40px;
}

ul.pagination li:nth-of-last(1) {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

ul.pagination li:last-child {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}


ul.pagination li.active {
  background: #4ABAEA;
  border-radius: 50%;
}


ul.pagination li {
  width: 40px;
  font-size: 20px;
  height: 40px;
  background: white;
  justify-content: center;
  border-radius: 50%;
  margin: 0 5px;
  vertical-align: middle;
  display: flex;
  align-items: center;
}

ul.pagination li.active a {
  color: white;
}


ul.pagination li:first-child, ul.pagination li:last-child {
  display: none;
}

ul.pagination li:nth-of-last(1) {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

ul.pagination li:last-child {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.pagination li a {
  text-decoration: none;
}

.bot-text h2 {
  font-style: normal;
  padding-bottom: 0;
  font-weight: 700;
  font-size: 62px;
  font-family: 'Uber Move';
  line-height: 70px;
  text-align: left;
  text-transform: uppercase;
  color: #172036;
}

.bot-text h2 span {
  color: #388EC4;
}

.bot-text p {
  padding: 10px 0px;
  font-family: 'Uber Move';
  font-size: 20px;
  color: #182037;
}

.bot-text {
  position: relative;
}

img.bot-light {
  position: absolute;
  top: -193px;
  left: -65px;
  width: 98%;
}


.slide-text h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 53px;
  color: #41A4DB;
  font-family: 'Uber Move';
}

.slide-text p {
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 34px;
  color: #727272;
}

button.btn.btn-detail {
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 100%;
  color: #FFFFFF;
  background: #000000;
  border-radius: 100px;
  width: 200px;
  height: 61px;
  margin: 15px auto;
}

.welcome .card {
  background: #FFFFFF;
  border-radius: 40px;
  width: 307px;
  height: 470px;
  display: inline-block;
  text-align: center;
  margin: 0 35px;
}

.welcome .box .carousel-indicators button.active {
  background: #00A7EF;
  height: 12px;
  padding: 0px;
  border-bottom: 0;
  width: 44px;
  border-top: 0;
  border-radius: 5px;
}

.welcome a.carousel-control-prev, .welcome a.carousel-control-next {
  display: none;
}


.welcome .box .carousel-indicators button {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: none;
  opacity: 1;
}

.welcome .carousel.slide {
  padding: 80px 0px;
}


button.swal2-confirm.swal2-styled:focus {
  box-shadow: none;
}

.have-plan {
  margin: auto;
}

.have-plan .user-info {
  border-right: none;
}


/* rjt-plan-css */

.plans-heading {
  color: #fff;
  text-align: center;
  width: 80%;
  margin: 0 auto  70px;
}

.plans-heading h3 {
  padding-bottom: 15px;
}

.color-white{
  color: #fff;
}



@media (min-width: 1280px) and (max-width:1320px) {
  .welcome .card {
    background: #FFFFFF;
    border-radius: 40px;
    width: 307px;
    height: 470px;
    display: inline-block;
    text-align: center;
    margin: 0 15px;
  }

  .bot-text h2 {
    font-size: 40px;
  }
}

@media (min-width: 960px) and (max-width:980px) {
  .table.radius-20 tr td {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 34px;
    color: #333333;
  }

  button.btn.btn-detail {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 100%;
    color: #FFFFFF;
    background: #000000;
    border-radius: 100px;
    width: 200px;
    height: 61px;
    margin: 15px auto;
  }

  .welcome .box {
    background: #000;
    padding: 60px 10px;
  }

  .slide-text h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 53px;
    color: #41A4DB;
    font-family: 'Uber Move';
  }

  .slide-text p {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 34px;
    color: #727272;
  }

  .welcome .card {
    background: #FFFFFF;
    border-radius: 40px;
    width: 215px;
    height: 470px;
    display: inline-block;
    text-align: center;
    margin: 0 5px;
  }
}

@media (max-width: 1200px) {
  .footer-email-area {
    margin-top: 20px;
  }

  .emoji {
    background-image: unset;
  }

  .userinfo input {
    width: 100%;
  }

  .header a.nav-link {
    margin: 0 10px;
    white-space: nowrap;
  }

  .banner {
    background-color: black;
  }

  .happy-client {
    padding: 50px 20px;
  }

  .figure-left img.abt-us {
    max-width: 100%;
    /* border: 1px solid #eee; */
    border-radius: 10px;
    padding: 0;
  }

  .figure-left {
    margin-left: 0;
  }

  .about-info {
    background: black;
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
    padding: 100px 50px;
  }

  .bot-text h2 {
    font-size: 50px;
  }

  .welcome .card {
    background: #FFFFFF;
    border-radius: 40px;
    width: 240px;
    height: 470px;
    display: inline-block;
    text-align: center;
    margin: 0 20px;
  }

  .welcome {
    background: white;
    padding: 50px 20px;
  }
}

@media (max-width: 992px) {
  .field-group span input[type="submit"] {
    padding: 8px 28px;
    right: 29px;
  }

  .footer {
    padding: 70px 0 20px 0;
  }

  .tab-area {
    padding: 50px 0 70px 0;
  }

  .header .navbar-nav {
    text-align: center;
    margin-top: 12px;
  }

  .social-links h6,
  .footer-email-area h6 {
    text-transform: uppercase;
    font-size: 16px;
    margin-bottom: 30px;
  }



}

@media (max-width: 768px) {
  .field-group span input[type="submit"] {
    padding: 8px 50px;
    right: 29px;
  }

  .trial-period {
    position: unset;
  }

  .user-detail, .user-info {
    padding: 30px;
  }

  button.change-pass.btn.btn-unset {
    font-size: 14px;
  }

  .user-info .card-heading a {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    cursor: pointer;
    line-height: 100%;
    text-decoration-line: none;
    color: #FFFFFF;
    border: 1px solid #fff;
    padding: 12px 20px;
    border-radius: 50px;
  }

  .user-detail {
    padding: 20px;
  }

  .disclaimer p {
    font-size: 14px;
  }

  .user-detail .card-heading h2 {
    font-size: 20px;
  }

  .transaction-history-main table td {
    font-size: 13px;
  }

  .transaction-history-main table th {
    white-space: nowrap;
    font-size: 14px;
  }

  .transaction-history-main .card-heading h2 {
    font-size: 20px;
  }

  .user-info .card-heading {
    display: block;
    justify-content: space-between;
  }

  .editchange {
    margin-top: 30px;
  }

  .banner-caption h1 {
    font-size: 35px;
    line-height: 44px;
  }

  .happy-client {
    margin-bottom: 25px;
  }

  .about-info {
    background: black;
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
    padding: 80px 20px;
  }

  img.line-arrow {
    display: none;
  }

  .telegram {
    padding: 0px 20px;
    background: white;
    position: relative;
  }

  .reddit {
    padding: 50px 18px 0px;
    background: #fff;
  }

  .trading {
    background-size: cover;
    background-position: center center;
    background-color: black;
    height: auto;
    padding: 40px 0px;
  }

  .trading-caption h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
  }

  .how-it h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 63px;
    font-family: 'Uber Move';
    text-align: center;
    color: #182037;
  }

  .box p {
    font-family: 'Uber Move';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    color: rgba(255, 255, 255, 0.87);
  }

  .box h3 {
    padding-bottom: 10px;
    font-style: normal;
    font-family: 'Uber Move';
    font-weight: 700;
    font-size: 15px;
    line-height: 30px;
    text-align: center;
    color: #FFFFFF;
  }

  .figure-left {
    margin-left: 0;
  }

  .figure-left img.abt-us {
    max-width: 100%;
    border-radius: 10px;
    padding: 0;
  }

  .banner {
    background-image: none;
    background-color: black;
  }

  .happy-client h4 {
    text-align: center;
  }

  .client-box .figure {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .client-box {
    display: block;
    text-align: center;
  }

  .happy-client-all h2 {
    padding-bottom: 30px;
    font-style: normal;
    font-weight: 700;
    font-family: 'Uber Move';
    font-size: 26px;
    line-height: 44px;
    padding-top: 10px;
    color: #182037;
  }

  .banner-box {
    margin: 45px 0px;
  }

  .banner-caption p br {
    display: none;
  }

  .banner-caption p {
    font-size: 16px;
  }

  img.banner-right-figure {
    position: absolute;
    right: 0;
    top: auto;
    bottom: 0px;
  }

  .prom-box h4 {
    font-size: 30px;
    line-height: 50px;
  }

  .promoter-coins {
    padding: 0px 20px 200px;
  }

  table.table.table-responsive.text-black.bg-white.radius-20 {
    table-layout: auto;
  }


  .slide-text p {
    color: black;
  }

  img.bitcoin-pic.mover, img.gift.mover {
    display: none;
  }


  .welcome .card:nth-of-type(2), .welcome .card:nth-of-type(3) {
    display: none;
  }

  .welcome {
    background: white;
    padding: 50px 5px;
  }

  .welcome .card {
    background: #FFFFFF;
    border-radius: 40px;
    width: 238px;
    height: auto;
    display: inline-block;
    text-align: center;
    margin: 0;
  }

  table.table.table-responsive.text-black.bg-white.radius-20 tr, table.table.table-responsive.text-black.bg-white.radius-20 tr td {
    padding: 25px 13px;
    border-bottom: 1px solid #E5E5E5;
  }

  table.table.table-responsive.text-black.bg-white.radius-20 tr td:first-child {
    padding-left: 0px;
  }

  .table.radius-20 tr td {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 34px;
    color: #333333;
    white-space: nowrap;
  }

  .prom-box {
    padding: 60px 20px;
  }

  .trading-caption {
    width: auto;
    margin: auto;
    text-align: center;
  }

  .bot-text p {
    font-size: 17px;
  }

  .bot-text h2 {
    font-size: 35px;
    line-height: normal;
  }

  .user-detail .card-heading {
    display: block;
  }

  .user-plan button {
    margin: 20px 0px;
  }

  .user-detail .card-heading h3 {
    font-style: normal;
    font-size: 18px;
    line-height: 28px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: #FFFFFF;
    padding-top: 10px;
  }

  .user-plan ul {
    margin-top: 10px;
    column-count: 1;
    display: flex;
  }

  .userinfo form {
    margin-top: 30px;
  }

  .userinfo input {
    background: #000000;
    border-radius: 100px;
    height: 50px;
    border: none;
    width: 100%;
    color: white;
  }

  .plans-content-area {
    min-height: max-content;
  }

  .footer-bottom-right ul li {
    margin-left: 20px;
  }

  .footer-bottom-right ul li:after {
    right: -10px;
  }

  .header a.nav-link {
    margin: 0 10px;
  }

  .tab-heading-area h1 {
    font-size: 29px;
  }

  .navbar-brand img {
    width: 80px;
    margin-right: 0;
  }

  .social-links h6,
  .footer-email-area h6 {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .footer-bottom-right {
    justify-content: center;
    margin-top: 10px;
  }

  .footer-bottom p {
    text-align: center;
  }

  .social-links ul li i {
    margin: 0;
    font-size: 18px;
  }

  .social-links li img {
    margin-top: 2px;
    width: 30px;
  }

  .footer {
    padding: 40px 0 20px 0;
    text-align: center;
  }

  .social-links ul {
    justify-content: center;
  }


  .signup-bot {
    max-width: 90%;
  }
}

@media (max-width: 576px) {
  .plans-content-area:hover {
    transform: unset;
  }

  .subscription-area {
    padding: 30px 0 90px 0;
  }

  .tab-area {
    padding: 50px 0 70px 0;
  }

  .signup-bot {
    margin: 35px auto 70px;
    padding: 15px;
  }

  .navbar-toggler {
    padding: 2px 5px;
  }
}

@media (max-width: 480px) {
  .field-group span input[type="submit"] {
    padding: 8px 30px;
  }

  .footer {
    padding: 30px 0 20px 0;
  }

  .footer-bottom p {
    font-size: 14px;
  }

  .footer-bottom-right {
    margin-top: 5px;
    font-size: 14px;
  }

  .social-links ul li i {
    margin: 0 5px;
  }

  .footer-logo {
    width: 70px;
    margin: 0 auto;
  }

  .header span.navbar-brand img {
    width: 70px;
  }

  p.signup-back-btn {
    font-size: 14px;
  }

  .tab-content-plans .nav-tabs {
    margin: 20px auto;
    padding: 7px 12px;
  }

  .tab-content-plans .tab-content {
    margin-top: 40px;
  }

  .tab-heading-area h1 {
    font-size: 24px;
    margin-bottom: 10px;
  }

  .tab-heading-area p {
    font-size: 14px;
    line-height: 20px;
  }

  .tab-content-plans .nav-tabs .nav-link {
    font-size: 14px;
  }
}